import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomLoginService } from '../../shared/services/custom-login.service';
import { MessageService } from 'primeng/api';

interface Questions {
  name: string;
  code: string;
}

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrl: './user-account.component.scss'
})
export class UserAccountComponent implements OnInit {
  questions: Questions[];

  formGroup: FormGroup;
  userForm: FormGroup
  passwordError: string;
  newPasswordError: any;
  confirmPasswordError: string;
  answer1Error: string;
  answer2Error: string;
  answer3Error: string;
  uniqueQuestion: boolean = true;
  requestNewAccount:boolean = true;

  constructor(private fb: FormBuilder, private customLoginService: CustomLoginService,  private messageService: MessageService, public router: Router) {}

  ngOnInit(): void {
    this.questions = [
      { name: 'Question 1', code: 'Q1' },
      { name: 'Question 2', code: 'Q2' },
      { name: 'Question 3', code: 'Q3' },
      { name: 'Question 4', code: 'Q4' },
      { name: 'Question 5', code: 'Q5' }
    ];

    this.userForm = this.fb.group({
      firstName: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)]),
      lastName: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)]),
      middleName: this.fb.control('', Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)),
      email: this.fb.control('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9](?!.*[._-]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9])@([a-zA-Z0-9](?!.*[.-]{2})[a-zA-Z0-9.-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,10}$/i), this.forbiddenDomainValidator]),
      confirmEmail: this.fb.control('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9](?!.*[._-]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9])@([a-zA-Z0-9](?!.*[.-]{2})[a-zA-Z0-9.-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,10}$/i), this.forbiddenDomainValidator]),
      question1: this.fb.control('', Validators.required),
      question2: this.fb.control('', Validators.required),
      question3: this.fb.control('', Validators.required),
      answer1: this.fb.control('', Validators.required),
      answer2: this.fb.control('', Validators.required),
      answer3: this.fb.control('', Validators.required),
      uniqueQuestion: this.fb.control(''),
      newPassword: this.fb.control('', Validators.required),
      confirmPassword: this.fb.control('', Validators.required),
    });
    this.customLoginService.getAllSecurityQuestions().subscribe(
      (securityQuestions) => {
        console.log(securityQuestions);
        this.questions = securityQuestions;
      });


    this.formGroup = this.fb.group({
      selectedQuestion: [''], // Initialize with an empty value
    });
  }

  get email(): AbstractControl { 
    return this.userForm.get('email')!; 
  } 
  
  forbiddenDomainValidator(control: AbstractControl): ValidationErrors | null { 
    const email: string = control.value || ''; 
    return email.endsWith('@faa.gov') ? { forbiddenDomain: true } : null; 
  }

  validateSecurityQuestion(key: any, qstn: any, answr: any) {
    let error = '';
    let question;
    let answer;
    question = this.userForm.controls[qstn].value?.securityQuestionDesc;
    answer = this.userForm.controls[answr].value;
    

    const isAlpha = (str: string) => /^[A-Za-z\s]+$/.test(str);
    const isAlphaNumeric = (str: string) => /^[A-Za-z0-9\s]+$/.test(str);
    const isFourDigitPIN = (str: string) => /^[0-9]{4}$/.test(str);
    const isNumeric = (str: string) => /^[0-9]+$/.test(str);
    const isDateFormat = (str: string) => /^(0[1-9]|1[0-2])[\/\-]?(0[1-9]|[12][0-9]|3[01])[\/\-]?\d{4}$/.test(str);
    const isPhoneNumber = (str: string) => /^(\d{1,3}-)?\d{3}-\d{3}-\d{4}$/.test(str) && str.replace(/[^0-9]/g, "").length <= 11;

    switch (question) {
      case "Mother's maiden name":
      case "City you were born in":
      case "Street you grew up on":
      case "Pet's name":
      case "Your favorite quote":
      case "Father's first name":
      case "Birth Stone":
      case "Zodiac sign":
      case "Favorite Flower":
      case "Elementary school name":
        if (!isAlpha(answer)) {
          error = "Please check entered value for security question.";
        }
        break;

        case "Four digit PIN":
          if (!isFourDigitPIN(answer)) {
            error = "Please check entered PIN for security question. It must be exactly 4 digits.";
          }
          break;
    
        case "Street number":
          if (!isNumeric(answer)) {
            error = "Please check entered value for security question.";
          }
          break;

      case "Date of birth":
      case "Anniversary date":
        if (!isDateFormat(answer)) {
          error = "Please check entered date for security question. Date must be in the following format (mm/dd/yyyy) or (mmddyyyy)";
        }
        break;

      case "Home Phone":
      case "Cell Phone":
        if (!isPhoneNumber(answer)) {
          error = "Please check entered phone number for security question. Format: 678-900-9088";
          if (answer.replace(/[^0-9]/g, "").length > 11) {
            error = "If CL > 11 CL - Please check entered phone number for security question.";
          }
        }
        break;

      default:
        error ="Select security question.";
    }

    if(key === 'question1') {
      this.answer1Error = error;
    } else if(key === 'question2') {
      this.answer2Error = error;
    } else if(key === 'question3') {
      this.answer3Error = error;
    }

    const question1 = this.userForm.controls['question1'].value?.securityQuestionDesc;
    const question2 = this.userForm.controls['question2'].value?.securityQuestionDesc;
    const question3 = this.userForm.controls['question3'].value?.securityQuestionDesc;

    if (
      (question1 !== question2 ||
        question1 === undefined ||
        question2 === undefined) &&
      (question1 !== question3 ||
        question1 === undefined ||
        question3 === undefined) &&
      (question2 !== question3 ||
        question2 === undefined ||
        question3 === undefined)
    ) {
      this.uniqueQuestion = true;
      this.userForm.controls.uniqueQuestion.setErrors(null);
    } else {
      this.uniqueQuestion = false;
      this.userForm.controls.uniqueQuestion.setErrors({ incorrect: true });
    }

    if(error.length) {
      this.userForm.controls[answr].setErrors({ incorrect: true });
    } else {
      this.userForm.controls[answr].setErrors(null);
    }
    this.userForm.updateValueAndValidity();
  }


  validatePassword(type: string, control: string) {
    const password = this.userForm.controls[control].value;
    console.log('password');
    let errorMessage = '';
 

    // Check if the passwords match
    if (type === 'confirmPassword') {
      if (password === '') {
        errorMessage = 'Confirm Password is required.';
      } else if((this.userForm.controls['newPassword'].value !== '') 
        && (password !== this.userForm.controls['newPassword'].value)) {
        errorMessage = 'Password and confirm password do not match.';
      } else if ((password.value !== '') && (this.userForm.controls['newPassword'].value !== '') 
        && password.value === this.userForm.controls['newPassword'].value && password.valid) {
        errorMessage = '';
      }
    } else {
      if (password === '') {
        errorMessage = 'Password is required.';
      } else if (password.length < 12 || password.length > 24) { // Check length requirement
        errorMessage = 'Password must contain between 12 and 24 characters';
      } else if (!/^[a-zA-Z]/.test(password)) { // Check if the password begins with a letter
        errorMessage = 'Password must begin with a letter';
      } else if (/([a-zA-z0-9]|[!#$%])\1/.test(password)) { // Check for no repeated characters in sequence OR (\w|[!#$%])\1
        errorMessage = 'No character may be repeated in sequence';
      } else {
        // Check for required character types
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!#\$%]/.test(password);
        const hasOtherChar = /^[a-zA-Z0-9!#$%]+$/.test(password);
      
        if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar || !hasOtherChar) {
          errorMessage = 'Password must include four types of characters: English upper case letter, English lower case letter, number, and allowable special characters (!, #, $, %)';
        } else if ((this.userForm.controls['confirmPassword'].value !== '') 
          && (password !== this.userForm.controls['confirmPassword'].value)) {
          errorMessage = 'Password and confirm password do not match.';
        } else if ((this.userForm.controls['confirmPassword'].value !== '') && (password.value !== '') 
          && this.userForm.controls['confirmPassword'].value === password.value && password.valid) {
          errorMessage = '';
        }
      }
    }

    if(errorMessage.length) {
      if(type === 'newPassword') {
        this.newPasswordError =  errorMessage;
        this.userForm.controls.newPassword.setErrors({ incorrect: true });
      } else {
        this.confirmPasswordError =  errorMessage;
        this.userForm.controls.confirmPassword.setErrors({ incorrect: true });
      }
    } else {
      if(type === 'newPassword') { 
        this.userForm.controls.newPassword.setErrors(null);
      }  if ((this.userForm.controls['confirmPassword'].value !== '') && (this.userForm.controls['newPassword'].value !== '') 
        && (this.userForm.controls['confirmPassword'].value === this.userForm.controls['newPassword'].value) && (this.userForm.controls['newPassword'].valid || this.newPasswordError ==='Password and confirm password do not match.')) {
          this.userForm.controls.confirmPassword.setErrors(null);
          this.userForm.controls.newPassword.setErrors(null);
        }
      if(this.userForm.controls['confirmPassword'].value ===''){
        this.userForm.controls.confirmPassword.setErrors({ incorrect: true });
      }
      if(this.userForm.controls['newPassword'].value ===''){
        this.userForm.controls.newPassword.setErrors({ incorrect: true });
      }
    }
    this.userForm.updateValueAndValidity();
  }

  createAccount() {
    const userObj = {
      "firstName": this.userForm.controls.firstName.value,
      "lastName": this.userForm.controls.lastName.value,
      "middleName": this.userForm.controls.middleName.value,
      "email": this.userForm.controls.email.value,
      "confirmEmail": this.userForm.controls.confirmEmail.value,
      "password": this.userForm.controls.newPassword.value,
      "confirmPassword": this.userForm.controls.confirmPassword.value,
      "userAccountQuestionAnswers": [
        {
          "questionId": this.userForm.controls.question1.value?.securityQuestionId,
          "questionAnswer": this.userForm.controls.answer1.value
        },
        {
          "questionId": this.userForm.controls.question2.value?.securityQuestionId,
          "questionAnswer": this.userForm.controls.answer2.value
        },
        {
          "questionId": this.userForm.controls.question3.value?.securityQuestionId,
          "questionAnswer": this.userForm.controls.answer3.value
        }
      ]
    };
    console.log(userObj);
    this.customLoginService.userRegistration(userObj).subscribe(
      (data) => {
        if (data && data.status) {
          this.requestNewAccount = false;
        } else {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Unable to Create Account',
            detail: data.message,
          });
        }
      },
      (error: any) => {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: 'Unable to Create Account',
          detail: error,
        });
      }
    );
  }

  signin() {
    this.router.navigateByUrl('/signin');
  }
}

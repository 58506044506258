<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">EXTERNAL USER- DESIGNATE A U.S. AGENT</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">As an external
                    user with a Non-FAA email once logged into USAS, you will be redirected to the following screen.
                    <img class="w-6 block" src="../../../assets/images/image18.png">
                </li>
                <li class="mb-3">As a first-time user logging into USAS, you will need to enter the ‘FTN’ and ‘DOB’ in the corresponding fields. If the correct FTN and date of birth (DOB) is entered and the FTN and date of birth (DOB) does not exist in the USAS Database, you will see the following message in the first attempt “<span
                        style="color: rgb(195, 32, 38);">Your FTN is not known to the USAS database. If you recently created your FTN, please click submit again to create a new U.S. Agent for Service.”</span> Once you enter the FTN and date of birth (DOB) twice you will see a blank ‘Manage U.S. Agent’ form displayed to designate your U.S. Agent. If the correct FTN and DOB is entered and the FTN and DOB exists in the USAS Database, in the first attempt itself, you will see a blank ‘Manage U.S. Agent’ form is displayed.</li>
                <li class="mb-3">A pop-up window
                    ‘New U.S. Agent’ will be displayed.
                    <img class="w-6 block mb-2" src="../../../assets/images/image19.png">
                    <img class="w-6 block" src="../../../assets/images/image20.png">
                </li>

                <li class="mb-3">
                    <p>Please select the
                        ‘Type of U.S. Agent’ radio button:</p>
                    <ul>
                        <li class="mb-3">
                            Individual (selected by default) OR
                        </li>
                        <li class="mb-3">
                            Service Agent Company
                        </li>
                    </ul>
                    <p><strong>Note:</strong> Please note that depending on the section of the ‘Type of U.S. Agent’ the form fields will be changed. </p>

                <li class="mb-3">Please fill out
                    all the required fields for the ‘Type of U.S. Agent’ selected and select the appropriate radio
                    button for
                    address verification and click on ‘Confirm my selection’ button.
                    <p><strong>Note:</strong> Once you click on ‘Confirm my selection’ the address verification section
                        will not be
                        displayed. </p>
                    <p><strong style="color: red;">Note:</strong><span style="color: red;"> Please enter a
                            U.S.&nbsp;physical address
                            for the Agent. No P.O. box information should be entered.</span></p>
                    <img class="w-6 block" src="../../../assets/images/image21.png">
                </li>
                <li class="mb-3">Check the ‘I
                    certify’ checkbox.
                </li>
                <li class="mb-3">Click on ‘Submit’.
                </li>
                <li class="mb-3">
                    A success message
                    will be displayed. Your agent information will be saved successfully, and you will be redirected
                    back to the
                    ‘Manage U.S. Agent’ page and will the display card with all the information as below:
                    <img class="w-6 block" src="../../../assets/images/image22.png">
                </li>
                <li class="mb-3">Please click on
                    ‘Edit or Update’ link to update the Agent Information.
                    <img class="w-6 block" src="../../../assets/images/image23.png">
                </li>
                <li class="mb-3">The prefilled ‘New U.S. Agent’ form
                    will be displayed and available for any edits. Once the information is updated, please click on
                    ‘Submit’ to
                    save.
                    <img class="w-6 block" src="../../../assets/images/image24.png">
                </li>
                <li class="mb-3">If the ‘Agent Type’ selected on the
                    ‘New U.S. Agent’ form is a ‘Service Agent Company’ the following information will need to be filled
                    and
                    submitted.
                    <img class="w-6 block" src="../../../assets/images/image25.png">
                </li>
                <li class="mb-3">The External user can also see the
                    ‘Resource Links’ tab with the following sub menu items:
                    <img class="w-6 block" src="../../../assets/images/image26.png">
                </li>
            </ol>
        </div>
    </div>
</div>
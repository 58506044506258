<div class="col-12 mt-4 px-6">
    <h1>U.S. Agent Details</h1>
  </div>
<div class="col-10 xl:col-12 lg:col-10 md:col-10 sm:col-12 mb-4 px-6" >  
    <div class="border-round-lg p-4 h-auto" style="background-color: #8080801a; font-size: 16px !important;">
      <div class="grid border-bottom-3 border-yellow-600">
        <div class="col-6 xl:col-6 lg:col-6 md:col-6">
          <div class="text-sm font-semibold mb-0">
            AR Owner
          </div>
          <div class="text-xl mt-0 capitalize">{{agentDetails?.arOwnerName}}</div>
        </div>
        <div class="col-6 xl:col-6 lg:col-6 md:col-6 justify-content-end flex-grow-1">
          <!-- <a class="edit-link text-sm text-color-primary" (click)="addAgent()" style="float: right;"><i
              class="fa-solid fa-pencil mr-1"></i>Edit or Update</a> -->
          <br>
          <p class="m-t-0 text-sm" style="float: right;">Last updated: {{agentDetails?.lastUpdateDate}}</p>
        </div>
      </div>
      <div class="grid mt-2">
        <div class="col-12">
          <h2 *ngIf="agentDetails?.isAgentIndividual=== 'Y'" class="text-lg font-semibold text-blue-800 mt-0">Individual
            U.S. Agent Details</h2>
          <h2 *ngIf="agentDetails?.isAgentIndividual === 'N'" class="text-lg font-semibold text-blue-800 mt-0">Service
            Agent Company Details</h2>

          <div *ngIf="agentDetails?.isAgentIndividual=== 'Y'" class="text-sm font-semibold mb-0">Individual U.S. Agent
          </div>
          <div *ngIf="agentDetails?.isAgentIndividual === 'N'" class="text-sm font-semibold mb-0">Service Agent Company
          </div>
          <div *ngIf="agentDetails?.isAgentIndividual=== 'Y'">{{agentDetails?.firstName}} {{agentDetails?.middleName}}
            {{agentDetails?.lastName}} {{agentDetails?.suffix}}</div>
          <div *ngIf="agentDetails?.isAgentIndividual=== 'N'"> {{agentDetails?.agentCompanyName}}</div>
        </div>
      </div>
      <div class="grid" *ngIf="agentDetails.isAgentMilitaryEmp=== 'Y' && agentDetails?.isAgentIndividual=== 'Y'">
        <div class="col-12">
          <div class="text-sm font-semibold mb-0">Military</div>
          <div>Yes</div>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <div class="text-sm font-semibold mb-0">Address</div>
          <div>{{agentDetails?.addressLine1}}</div>
          <div>{{agentDetails?.addressline2}}</div>
          <div>{{agentDetails?.city}}, {{agentDetails?.stateCd}} {{agentDetails?.zipCode}}<span
              *ngIf="agentDetails?.zip4Code">-{{agentDetails?.zip4Code}}</span>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-4 md:col-4 sm:col-12">
          <div class="text-sm font-semibold mb-0">Phone</div>
          <div *ngIf="agentDetails?.phone">{{ agentDetails?.phone}}</div>
        </div>
        <div class="col-6 md:col-6 sm:col-12">
          <div class="text-sm font-semibold mb-0">Fax</div>
          <div>{{agentDetails?.fax}}</div>
        </div>
      </div>
      <div class="grid">
        <div class="col-6 md:col-6 sm:col-12">
          <div class="text-sm font-semibold mb-0">E-mail</div>
          <div>{{agentDetails?.email}}</div>
        </div>
      </div>
      <div *ngIf="agentDetails?.isAgentIndividual=== 'N'">
        <div class="grid border-top-2 mt-2">
          <div class="col-12">
            <h2 class="text-lg font-semibold mb-0 text-blue-800">Service Agent Company Representative</h2>
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <div class="text-sm font-semibold mb-0">Name</div>
            <div class="capitalize">{{agentDetails?.firstName}} {{agentDetails?.middleName}} {{agentDetails?.lastName}}
              {{agentDetails?.suffix}}</div>
          </div>
        </div>
        <div class="grid">
          <div class="col-4 md:col-4 sm:col-12">
            <div class="text-sm font-semibold mb-0">Phone</div>
            <div *ngIf="agentDetails?.pocPhone">{{ agentDetails?.pocPhone }}</div>
          </div>
          <div class="col-6 md:col-6 sm:col-12">
            <div class="text-sm font-semibold mb-0">Fax</div>
            <div>{{agentDetails?.pocFax}}</div>
          </div>
        </div>
        <div class="grid">
          <div class="col-6 md:col-6 sm:col-12">
            <div class="text-sm font-semibold mb-0">E-mail</div>
            <div>{{agentDetails?.pocEmail}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

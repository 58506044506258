import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddAgentModalComponent } from '../add-agent-modal/add-agent-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UsasLoadingService } from '../../shared/components/usas-loading/usas-loading.service';
import { AuthService } from "../../_auth/auth.service";
import { AgentDetailsService } from '../../shared/services/save-agent-details.service';
import { AgentData } from '../../shared/models/agent-data-model';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { DesignateAgentModalComponent } from '../designate-agent-modal/designate-agent-modal.component';
import { DisplayAgentDetailsComponent } from '../display-agent-details/display-agent-details.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
  providers: [DynamicDialogRef, DynamicDialogConfig]
})

export class LandingPageComponent implements OnInit {
  showAddAgentModal = false;
  showEditMode = false;
  showAREditMode = false;
  addagentform: any;
  isLoading: boolean = false;
  userName = 'Guest';
  agentDetails: any;
  userData: any;
  ftnSearch: UntypedFormGroup | any;
  roleSelection: UntypedFormGroup | any;
  ARSearch: UntypedFormGroup | any;
  count: number = 0;
  isError: boolean = false;
  maxdobDate: Date;
  errorMsg: string;
  ftn: string;
  dob: string;
  applicantId: number;
  showAROwnerSection: boolean = false;
  showFTNSection: boolean = false;
  showAircraftSection: boolean = false;
  showRoleSelectionSection: boolean = true;
  roleSelected: boolean;
  isArError: boolean = false;
  errorMessage: string;
  nNumber: string;
  serialNumber: string;
  arOwnerDetails: any;
  arOwnerList: any[] = [];
  isNewAgent: boolean = false;
  agentDetailsList: any[] = [];
  isClearRoleSelected:boolean = false;

  categories: any[] = [
    { name: 'Airman / Operator', key: 'A', value: true },
    { name: 'Aircraft Owner', key: 'O', value: false }
  ];

  constructor(private fb: UntypedFormBuilder, private dialogService: DialogService, private usasLoadingService: UsasLoadingService,
    public agentDialogRef: DynamicDialogRef, private config: DynamicDialogConfig, private authService: AuthService, private agentDetailsService: AgentDetailsService, public designateDialogRef: DynamicDialogRef,
    private messageService: MessageService, public router: Router,) {
    this.usasLoadingService.isLoading.next(false);
    window.dispatchEvent(new CustomEvent("updateHeader"));
    this.maxdobDate = new Date(); // Set maxDate to today
  }
  ngOnInit(): void {
    this.ftnSearch = this.fb.group({
      ftnNumber: this.fb.control('', [Validators.required, Validators.pattern("^[a-zA-Z0-9]{8}$")]),
      dob: this.fb.control('', Validators.required),
    })
    this.roleSelection = this.fb.group({
      selectedCategory: this.fb.control(''),
    })
    this.ARSearch = this.fb.group({
      nNumber: this.fb.control('', [Validators.required, Validators.pattern(/^[0-9]*[0-9][A-Za-z0-9-]*$/)]),
      serialNumber: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9-]*$/)]),
    })
    const userData = this.authService.getUserData();
    if ((this.authService.isInternalUser() || this.authService.isInternalAdminUser()) && userData.userIsActive === 'Y') {
      this.router.navigate(["/search"]);
    } else if (userData.userIsActive === 'N') {
      this.router.navigate(['/access-denied']);
    }
    this.userData = this.authService.getUserData()
    const validToken = this.authService.isValidJWT();
    if (validToken) {
      this.userName = this.userData.firstName + ' ' + this.userData.lastName;
      if (sessionStorage.getItem('showEditMode')) {
        this.showEditMode = true;
        this.handleFtnSubmit(sessionStorage.getItem('ftn'), sessionStorage.getItem('dob'));
      }
      if (sessionStorage.getItem('showAREditMode')) {
        this.showAREditMode = true;
        if(sessionStorage.getItem('arOwnerList')){
          const arOwnerList:any = sessionStorage.getItem('arOwnerList');
          const agentDetailsList:any = sessionStorage.getItem('agentDetailsList')
          this.arOwnerList = JSON.parse(arOwnerList);
          this.agentDetailsList = JSON.parse(agentDetailsList);
        }else{
          this.handleARSubmit(sessionStorage.getItem('nNumber'), sessionStorage.getItem('serialNumber'));
        }  
      }
    }
    this.roleSelection.get('selectedCategory').valueChanges.subscribe((roleSelected: any) => {
      this.roleSelected = roleSelected.value;
      this.isClearRoleSelected = false;
    })
  }


  addAgent() {
    if (this.showEditMode === true || this.showAREditMode === true) {
      this.agentDialogRef = this.dialogService.open(AddAgentModalComponent, {
        header: 'Manage U.S. Agent',
        width: '58%',
        contentStyle: { 'padding': '0 25px 0 75px', 'font-size': '12px' },
        styleClass: 'agentDialogStyles',
        data: this.agentDetails
      });
    } else {
      this.agentDialogRef = this.dialogService.open(AddAgentModalComponent, {
        header: 'Manage U.S. Agent',
        width: '61%',
        contentStyle: { 'padding': '0 25px 0 75px', 'font-size': '12px' },
        styleClass: 'agentDialogStyles',
      });
    }

    this.agentDialogRef.onClose.subscribe((formData) => {
      const agentFormData = formData?.formData;
      if (agentFormData && agentFormData?.status !== "INVALID" && !formData?.isCancel) {
        const reqData: AgentData = {
          isNewAgent: this.showEditMode || this.showAREditMode ? "N" : 'Y',
          agentCompanyName: agentFormData.value.agentCompanyName?.trim(),
          firstName: !agentFormData.value.isAgentIndividual ? agentFormData.value.firstName?.trim() : agentFormData.value.pocfirstName?.trim(),
          lastName: !agentFormData.value.isAgentIndividual ? agentFormData.value.lastName?.trim() : agentFormData.value.poclastName?.trim(),
          suffix: !agentFormData.value.isAgentIndividual ? agentFormData.value.suffix?.trim() : agentFormData.value.pocsuffix?.trim(),
          middleName: !agentFormData.value.isAgentIndividual ? agentFormData.value.middleName?.trim() : agentFormData.value.pocmiddleName?.trim(),
          isAgentIndividual: !agentFormData.value.isAgentIndividual ? "Y" : "N",
          isAgentMilitaryEmp: agentFormData.value.isAgentMilitaryEmp ? 'Y' : 'N',
          addressLine1: agentFormData.value.addressLine1?.trim(),
          addressline2: agentFormData.value.addressline2?.trim(),
          city: agentFormData.value.city?.trim(),
          stateCd: agentFormData.value.state,
          zipCode: Number(agentFormData.value.zipCode),
          zip4Code: agentFormData.value.zip4Code ? Number(agentFormData.value.zip4Code) : null,
          email: agentFormData.value.email?.trim(),
          phone: agentFormData.value.phone ? agentFormData.value.phone : "",
          fax: agentFormData.value.fax ? agentFormData.value.fax : "",
          militaryUnitNo: 0,
          militaryBoxNo: 0,
          pocEmail: agentFormData.value.isAgentIndividual ? agentFormData.value.pocEmail?.trim() : "",
          pocFax: agentFormData.value.isAgentIndividual ? agentFormData.value.pocFax?.trim() : "",
          pocPhone: agentFormData.value.isAgentIndividual ? agentFormData.value.pocPhone?.trim() : ""
        }
        if (this.roleSelected === false || this.showAREditMode) {
          reqData.arOwnerId = this.arOwnerDetails
          reqData.systemDesignatedExternalUserId = this.userData.userId
          if (this.isNewAgent) {
            reqData.arOwnerAgentId = this.agentDetails.arOwnerAgentId;
            this.agentDetailsService.UpdateAROwnerAgentDetails(reqData).subscribe((data) => {
              if (data) {
                if(data.status === true){
                  this.showAREditMode = true;
                  sessionStorage.setItem('showAREditMode', "true");
                  this.handleARSubmit(sessionStorage.getItem('nNumber'), sessionStorage.getItem('serialNumber'));
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                   detail: 'U.S. Agent saved successfully',
                  });
                }else {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                   detail: 'Error occured while saving agent details, please try again later.',
                  });

                }
              }
            });

          } else {
            this.agentDetailsService.AddAROwnerAgent(reqData).subscribe((data) => {
              if (data) {
                if(data.status === true){
                  this.showAREditMode = true;
                  sessionStorage.setItem('showAREditMode', "true");
                  this.handleARSubmit(sessionStorage.getItem('nNumber'), sessionStorage.getItem('serialNumber'));
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'U.S. Agent saved successfully',
                  });
                }else{
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                   detail: 'Error occured while saving agent details, please try again later.',
                  });
                }
              }
            });
          }
        } else {
          reqData.lastUpdateBy = this.userData.userId;
          reqData.applicantId = this.applicantId;
          this.agentDetailsService.saveAgentDetails(reqData).subscribe((data) => {
            if (data) {
              this.showEditMode = true;
              sessionStorage.setItem('showEditMode', "true");
              this.handleFtnSubmit(sessionStorage.getItem('ftn'), sessionStorage.getItem('dob'));
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'U.S. Agent saved successfully',
              });

            }
          });
        }
        this.restArForm();
        this.restFtnForm();
      }
      else{
        this.arOwnerList = this.arOwnerList.filter(arOwner => arOwner.nNumber !== this.nNumber)
        sessionStorage.removeItem('nNumber');
        sessionStorage.removeItem('serialNumber');
        this.restArForm();
        this.restFtnForm();
      }
    });
  }
  handleARSubmit(nNumber?: string | null, serialNumber?: string | null) {
    if (this.ARSearch.valid || sessionStorage.getItem('showAREditMode')) {
      this.nNumber = nNumber ? nNumber : this.ARSearch.get('nNumber').value;
      this.serialNumber = serialNumber ? serialNumber : this.ARSearch.get('serialNumber').value;

      const reqData = {
        nNumber: this.nNumber,
        serialNumber: this.serialNumber,
        email: this.userData.mail || this.userData.sub
      }
      this.agentDetailsService.GetAROwnerDetails(reqData).subscribe((data) => {
        if (data) {
          if (data.status === false) {
            this.count++;
            if (this.count === 2) {
              this.count = 0;
              this.AddAROwner();
              this.isArError = false;
              this.addAgent();
            } else {
              this.isArError = true;
              this.errorMessage = data.message;
              this.ARSearch.reset();
            }
          }
          if (data.status === true) {
            sessionStorage.setItem('nNumber', this.nNumber);
            sessionStorage.setItem('serialNumber', this.serialNumber);  
            if (data.agentDetails == null) {
              this.AddAROwner();
              this.addAgent();
            } else {
              if (!this.showAREditMode) {
                this.designateDialogRef = this.dialogService.open(DesignateAgentModalComponent, {
                  header: 'Designate U.S. Agent ',
                  width: 'auto',
                  contentStyle: { 'padding': '24px, 40px, 24px, 40px' },
                  styleClass: 'agentDialogStyles'
                });
              }
              this.AddAROwner();
              this.agentDetails = data.agentDetails;
              this.agentDetails.nNumber = this.nNumber;
              const arOwnerIdExists = this.agentDetailsList.some(agentDetails => agentDetails.arOwnerId ===this.agentDetails.arOwnerId)
              if(!this.isNewAgent){
                if (this.agentDetailsList.length >= 0 && !arOwnerIdExists) {
                  this.agentDetailsList.push(this.agentDetails);
                }
              }else if(this.isNewAgent){
                if (this.agentDetailsList.length >= 0 && arOwnerIdExists) {
                  this.agentDetailsList = this.agentDetailsList.filter(agentDetails => agentDetails.arOwnerId !==this.agentDetails.arOwnerId)
                  this.agentDetailsList.push(this.agentDetails);
                }
              } 
            }
            const nNumberExists = this.arOwnerList.some(arOwner => arOwner.nNumber === this.nNumber);
            if (this.arOwnerList.length >= 0 && !nNumberExists) {
              this.arOwnerList.push({
                nNumber: this.nNumber, 
                serialNumber: this.serialNumber, 
                arOwnerName: this.agentDetails?.arOwnerName,
                lastUpdateDate: this.agentDetails?.lastUpdateDate
              })
            }
            sessionStorage.setItem('arOwnerList', JSON.stringify(this.arOwnerList));
            sessionStorage.setItem('agentDetailsList', JSON.stringify(this.agentDetailsList));
          }
        }
        this.designateDialogRef.onClose.subscribe((isNewAgent) => {
          if (isNewAgent && isNewAgent === true) {
            this.isNewAgent = true;
            this.addAgent();
          } else if (!isNewAgent && isNewAgent === false) {
            this.isNewAgent = false;
            this.showAREditMode = true;
            sessionStorage.setItem('showAREditMode', "true");
          }
        })
      });
    } else if (!this.ftnSearch.valid && !sessionStorage.getItem('showAREditMode')) {
      this.ARSearch.markAllAsTouched();
    }

  }

  handleFtnSubmit(ftn?: string | null, dob?: string | null) {
    if (this.ftnSearch.valid || sessionStorage.getItem('showEditMode')) {
      this.ftn = ftn ? ftn : this.ftnSearch.get('ftnNumber').value;
      const string = dob ? dob : this.ftnSearch.get('dob').value;
      this.dob = new Date(string).toISOString().split('T')[0];
      console.log(this.dob);
      const reqData = {
        ftn: this.ftn,
        dob: this.dob,
        email: this.userData.mail || this.userData.sub
      }
      this.agentDetailsService.GetExternalUserAgentsDetails(reqData).subscribe((data) => {
        if (data) {
          this.count++;
          if (data.message === "User not found." || data.message === "Airman details not found") {
            if (this.count === 2) {
              this.count = 0;
              this.UpdateFtnForExternalUser();
              this.addAgent();
              this.isError = false;
            } else {
              this.isError = true;
              this.ftnSearch.reset();
              this.errorMsg = `Your FTN is not known to the USAS database. If you recently created your FTN, please click submit again to create a new U.S. Agent for Service.`;
            }
          }
          else if (data.message === "Another User/Email is already associated to the FTN you have entered. Please check your FTN or Email and resubmit your request." ||
            data.message === "The FTN or DOB does not match with your registered email. Please check your FTN or DOB and resubmit your request."
          ) {
            this.isError = true;
            this.ftnSearch.reset();
            this.errorMsg = data.message;
          }
          else {
            if (data.agentDetails == null) {
              this.UpdateFtnForExternalUser();
              this.addAgent();
            } else {
              this.UpdateFtnForExternalUser();
              this.agentDetails = data.agentDetails;
              this.showEditMode = true;
              sessionStorage.setItem('showEditMode', "true");
              sessionStorage.setItem('ftn', this.ftn);
              sessionStorage.setItem('dob', this.dob);
            }
          }
        }
      });
    }
    else if (!this.ftnSearch.valid && !sessionStorage.getItem('showEditMode')) {
      this.ftnSearch.markAllAsTouched();
    }

  }
  restFtnForm() {
    this.isError = false;
    this.errorMsg = '';
    sessionStorage.setItem('ftn', this.ftn);
    sessionStorage.setItem('dob', this.dob);
    this.ftnSearch.reset();
  }
  restArForm() {
    this.isArError = false;
    this.errorMessage = '';
    sessionStorage.setItem('nNumber', this.nNumber);
    sessionStorage.setItem('serialNumber', this.serialNumber);
    this.ARSearch.reset();
  }

  //New API call to update FTN only while updating an agent
  UpdateFtnForExternalUser() {
    const reqData = {
      ftn: this.ftn,
      dob: this.dob,
      email: this.userData.mail || this.userData.sub
    }
    this.agentDetailsService.AddUpdateApplicantDetails(reqData).subscribe((data) => {
      if (data) {
        this.applicantId = data;
      }
    });
  }
  //New API call to update nNumber and serialNumber only while updating an agent
  AddAROwner() {
    const reqData = {
      nNumber: this.nNumber,
      serialNumber: this.serialNumber,
      arOwnerName: this.userData.lastName + ' ' + this.userData.firstName
    }
    this.agentDetailsService.AddAROwner(reqData).subscribe((data) => {
      if (data) {
        this.arOwnerDetails = data;
      }
    });
  }

  showChoice() {
    this.restArForm();
    if (this.roleSelected === true && !this.isClearRoleSelected) {
      this.showFTNSection = true;
      this.showAROwnerSection = false;
      this.showRoleSelectionSection = false;
    }
    if (this.roleSelected === false && !this.isClearRoleSelected) {
      this.showFTNSection = false;
      this.showAROwnerSection = true;
      this.showRoleSelectionSection = false;
    }
  }
  showwAgent(nNumber:number) {
    const agentDetails = this.agentDetailsList.find(agentDetails => agentDetails.nNumber === nNumber)
    this.agentDialogRef = this.dialogService.open(DisplayAgentDetailsComponent, {
      header: 'Manage U.S. Agent',
      width: '58%',
      contentStyle: { 'padding': '0 25px 0 75px', 'font-size': '12px' },
      styleClass: 'agentDialogStyles',
      data: agentDetails
    });
  }

  addNewAgent() {
    this.isClearRoleSelected= true;
    this.showFTNSection = false;
    this.showAROwnerSection = false;
    this.showEditMode = false;
    this.showAREditMode = false;
    this.isNewAgent = false;
    this.showRoleSelectionSection = true;
    this.count = 0;
    sessionStorage.removeItem("showAREditMode");
    this.restArForm();
    this.restFtnForm();
    this.roleSelection.reset();
  }

}

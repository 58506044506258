<p-toast position="top-center"></p-toast>
<main id="main-content" class="grid nested-grid ">
    <div class="col-3 md:col-3">
        <div class="devkit-left-nav-link-wrapper w-auto" id="devkit-left-nav-link-wrapper" [formGroup]="userLogin">
            <ul class="devkit-left-nav-links border-solid border-gray-200 p-3 bg-gray-100" id="devkit-left-nav-links-login">
                <div class="devkit-nav-heading">External Users</div>
                <p class="text-sm">Please enter email and password to sign in</p>
                <div>
                    <label for="email1" class="block text-900 font-medium mb-1 mt-1">Email</label>
                    <input pInputText formControlName="email" (keyup.enter)="externalUserLogin()" id="email1" type="email" placeholder="Email address" pInputText class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full mb-1">
                    <div *ngIf="userLogin.controls.email.invalid && userLogin.controls.email.touched && userLogin?.controls?.email?.errors?.required"
                        class="alert danger-alert error-font">Email is required.</div>
                    <div *ngIf="userLogin.controls.email.invalid && userLogin.controls.email.touched && !userLogin?.controls?.email?.errors?.required"
                        class="alert danger-alert error-font">Please enter a valid email address.</div>
            
                    <label for="password1" class="block text-900 font-medium mb-1 mt-2">Password</label>
                    <!-- <input pInputText formControlName="password" (keyup.enter)="externalUserLogin()" id="password1" type="password" placeholder="Password" pInputText class="text-base text-color surface-overlay p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full mb-1"> -->
                    <p-password id="password1" formControlName="password" (keyup.enter)="externalUserLogin()" [feedback]="false" [toggleMask]="true" class="block border-noround" placeholder="Password"/>
                    <div *ngIf="userLogin.controls.password.invalid && userLogin.controls.password.touched"
                        class="alert danger-alert error-font">Password is required.</div>
                </div>
                <button (click)="externalUserLogin()" class="primary default" pButton pRipple type="button" label="Sign in" class="w-full primary default mt-4"></button>
                <div class="flex align-items-center justify-content-start mb-2">
                    <a class="font-medium no-underline link-button cursor-pointer ml-0" (click)="forgotPassword()">Forgot password?</a>
                </div>
                <button pButton pRipple type="button" (click)="createNewAccount()" label="Request New User Account" class="w-full secondary default mt-2"></button>
            </ul>
            <ul class="devkit-left-nav-links border-solid border-gray-200 p-3 bg-gray-100" id="devkit-left-nav-links-login">
                <div class="devkit-nav-heading">FAA Users</div>
                <button pButton pRipple type="button" label="FAA User Sign in" class="w-full primary default mt-3" (click)="login()"></button>
            </ul>
        </div>
    </div>
    <div class="col-9 md:col-9">
        <div class="grid">
            <div class="col-8">
                <h1>Welcome to USAS</h1>
                <hr class="border-top-3 border-yellow-600">
                <div class="pr-3">
                    <p>
                        On October 8, 2024, FAA published <span><a href="https://www.federalregister.gov/documents/2024/10/08/2024-22000/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain" aria-label="Federal Register" title="Federal Register" target="_blank">U.S Agents for Service final rule</a></span>
                    </p>
                    <p>
                        Individuals who have a foreign address and <b>no</b> U.S. physical address of record on file with the FAA are required to designate a U.S. agent for service if they apply for a certificate issued under 14 CFR part 47, 61, 63, 65, 67, or 107 or hold a certificate issued under any of these parts. This requirement applies only to individuals (not entities).
                    </p>
                    <p>
                        <b>Note: </b>If you do not currently have a U.S. physical address of record and are able to provide one, you may do so through the standard processes for <span><a href="https://www.faa.gov/licenses_certificates/airmen_certification/update_address" aria-label="Link to airman certification" title="Airman Certificates" target="_blank">Airmen</a></span> or <span><a href="https://www.faa.gov/licenses_certificates/aircraft_certification/aircraft_registry/change_of_address" aria-label="Link to aircraft registration" title="Airman Registration" target="_blank">Aircraft Owners</a></span> in lieu of designating a U.S. Agent for Service.
                    </p>
                </div>
            </div>
            <div class="col-3 md:col-3">
                <div class="devkit-left-nav-link-wrapper w-auto" id="devkit-left-nav-link-wrapper">
                    <ul class="devkit-left-nav-links p-3" id="devkit-left-nav-links-resources">
                        <div class="devkit-nav-heading">Resource Links</div>
                        <li><a class="devkit-left-nav-link"
                            routerLink="/user-guide" aria-label="Link to User Guide"
                            title="User Guide">User Guide</a></li>
                        <!-- <li><a class="devkit-left-nav-link"
                            href="#" aria-label="Link to Policy"
                            title="Policy" target="_blank">Policy</a></li> -->
                        <li><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0"
                            (click)="faqsList()" aria-label="Link to FAQs"
                            title="FAQs">FAQ</a></li>
                        <li><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0"
                            (click)="contactUs()" aria-label="Link to Policy"
                            title="Policy">Contact Us</a></li>
                        <li><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0" aria-label="Link to Help" title="Help" (click)="contactUs()">Help</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grid mt-3 mb-3 text-sm">
            <div class="col-8">
                <div class="devkit-info-message-wrapper" role="dialog" aria-label="info-message" aria-describedby="info-message-body">
                    <div class="devkit-message-body-wrapper">
                      <p class="devkit-message-body" id="info-message-body">
                        <b>Note:</b> Due to security requirements, after 60 minutes of inactivity you will 
                            be automatically logged off the application and required to login again before resuming activity
                      </p>
                    </div>
                  </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-8">
                <p-accordion class="text-sm">
                    <p-accordionTab header="Privacy Act Statement (5 U.S.C. § 552a, as amended)">
                        <dl>
                            <dt><b>Authority</b></dt>
                            <dd>Information solicited by the United States Agent for Service (USAS) Request an Account Form is collected under 49 U.S.C. 106(f), 106(g), 49 U.S.C. §§ 40113, 44101, 44109, 44110, 44702, 44703, 44709, 44807 and 14 C.F.R. Parts 47, 61, 63, 65, 67 and/or 107.</dd>
                            <dt><b>Purpose</b></dt>
                            <dd>The USAS Request an Account Form collects name, email address, answers to security questions and password information. This information is used to create a new user account for access to the USAS Portal.</dd>
                            <dt><b>Routine Uses</b></dt>
                            <dd>The information collected will be included in the system of records notice 
                                <a type="button" class="link-button"
                                href="https://www.govinfo.gov/content/pkg/FR-2002-05-07/pdf/02-10943.pdf" aria-label="DOT Records"
                                title="DOT Records" target="_blank">DOT/ALL 13- Internet/Intranet Activity and Access Records</a>
                                 and will be subject to the 
                                published routine uses including
                                <ul>
                                    <li>
                                        To provide information to any person(s) authorized to assist in an approved investigation of improper access or usage of DOT computer systems;
                                    </li>
                                    <li>
                                        To an actual or potential party or his or her authorized representative for the purpose of negotiation or discussion of such matters as settlement of the case, matter, or informal discovery proceedings;
                                    </li>
                                    <li>
                                        To contractors, grantees, experts, consultants, detailees, and other non-DOT employees performing or working on a contract, service, grant cooperative agreement, or other assignment from the Federal government, when necessary to accomplish an agency function related to this system of records; and
                                    </li>
                                    <li>
                                        To other government agencies where required by law.
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <p>
                            The Department has also published 15 additional routine uses applicable to all DOT Privacy Act system of records.  These routine uses are published in the Federal Register at 75 FR 82132 - December 29, 2010, 77 FR 42796 - July 20, 2012, and 84 FR 55222 - October 15, 2019, under “DOT General Routine Uses” (available at <a type="button" class="link-button"
                            href="https://www.transportation.gov/individuals/privacy/privacy-act-system-records-notices" aria-label="Privacy Act Notices"
                            title="Privacy Act Notices" target="_blank">https://www.transportation.gov/individuals/privacy/privacy-act-system-records-notices</a>).
                        </p>
                        <p>
                            <b>Disclosure:</b> Submission of the information is voluntary; however, failure to submit requested information will result in FAA’s inability to grant you access to the portal.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Information Paperwork Reduction Act Statement">
                        <p>
                            The information is necessary to determine your eligibility to become and FAA designee. 
                            It is estimated that it will take approximately one minute per response. The information 
                            will become part of the Privacy Act system of records, DOT / FAA 830 Representative of the 
                            Administrator, and it will be used to evaluate your qualifications for appointment as a designee. 
                            It should be noted that a person is not required to collect information unless it displays a 
                            currently valid OMB control number. The OMB control number associated with this collection of 
                            information is 2120-0033. Comments concerning the accuracy of this burden and suggestions for 
                            reducing the burden should be directed to the FAA at: 800 Independence Ave SW, Washington, DC 20591,
                            Attn: Information Collection Clearance Officer, ABA-20
                        </p>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</main>

<main id="main-content" class="grid nested-grid">
    <div class="col-8 mt-4 px-6">
        <div class="grid mb-2" *ngIf="userData?.userIsActive !== 'Y'">
            <button type="button" class="secondary default" (click)="signin()">Go Back</button>
        </div>
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12">
                <h1>FAQs</h1>
            </div>
        </div>
        <div class="grid">
            <div class="col-12">
                <p-accordion class="text-sm">
                    <p-accordionTab header="What is a U.S. Agent?">
                        <p>
                            A U.S. Agent for service is an individual adult (18 or older) or entity who a certificate holder or applicant designates to receive FAA service on their behalf.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="What actions does the U.S. Agent perform?">
                        <p>A U.S. Agent should timely transmit all documents the FAA serves on the U.S. Agent to the certificate, rating, or authorization holder or applicant that designated the U.S. Agent. Transmittal methods from the U.S. Agent to the individual might include, for example, scanning and emailing, faxing the documents, or sending the documents through expedited mail. The U.S. Agent and the individual who designated the U.S. Agent should agree in advance on what transmittal method would work best to ensure the U.S. Agent’s timely transmittal of FAA correspondence.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Why do I have to designate a U.S. Agent">
                        <p>
                            On October 8, 2024, FAA published <span><a href="https://www.federalregister.gov/documents/2024/10/08/2024-22000/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain" aria-label="Federal Register" title="Federal Register" target="_blank">U.S Agents for Service final rule</a></span>
                        </p>
                        <p>
                            Individuals who have a foreign address and <b>no</b> U.S. physical address of record on file with the FAA are required to designate a U.S. agent for service if they apply for a certificate issued under 14 CFR part 47, 61, 63, 65, 67, or 107 or hold a certificate issued under any of these parts. This requirement applies only to individuals (not entities).
                        </p>
                        <hr>
                        <p>
                            <b>Note: </b>If you do not currently have a U.S. physical address of record and are able to provide one, you may do so through the standard processes for <span><a href="https://www.faa.gov/licenses_certificates/airmen_certification/update_address" aria-label="Link to airman certification" title="Airman Certificates" target="_blank">Airmen</a></span> or <span><a href="https://www.faa.gov/licenses_certificates/aircraft_certification/aircraft_registry/change_of_address" aria-label="Link to aircraft registration" title="Airman Registration" target="_blank">Aircraft Owners</a></span> in lieu of designating a U.S. Agent for Service.
                        </p>
                        <hr>
                        <p>
                            Certificate holders/applicants/aircraft owners who only hold a medical certificate can contact the Aerospace Medical Certification Division to make updates to your mailing address if you are doing so between applications:
                        </p>
                        <address>
                            Federal Aviation Administration<br>
                            Aerospace Medical Certification Division, AAM-300<br>
                            CAMI, Bldg. 13<br>
                            PO Box 25082<br>
                            Oklahoma City, OK 73125<br>
                            Phone: (405) 954-4821
                        </address>
                    </p-accordionTab>
                    <p-accordionTab header="I have a U.S. physical address, why was I told to register a U.S. Agent for Service?">
                        <ul>
                            <li>
                                The FAA reviewed all addresses of record and determined you do not have a U.S. physical address.
                            </li>
                            <li>
                                If this is incorrect:
                                <ul>
                                    <li>Update your mailing address through the standard processes for <span><a href="https://www.faa.gov/licenses_certificates/airmen_certification/update_address" aria-label="Link to airman certification" title="Airman Certificates" target="_blank">Airmen</a></span> or <span><a href="https://www.faa.gov/licenses_certificates/aircraft_certification/aircraft_registry/change_of_address" aria-label="Link to aircraft registration" title="Airman Registration" target="_blank">Aircraft Owners</a></span> in lieu of designating a U.S. Agent for Service.</li>
                                    <li class="mt-2">Certificate holders/applicants/aircraft owners who only hold a medical certificate can contact the Aerospace Medical Certification Division to make updates to your mailing address if you are doing so between applications:</li>
                                    <address class="mt-2">
                                        Federal Aviation Administration<br>
                                        Aerospace Medical Certification Division, AAM-300<br>
                                        CAMI, Bldg. 13<br>
                                        PO Box 25082<br>
                                        Oklahoma City, OK 73125<br>
                                        Phone: (405) 954-4821
                                    </address>
                                </ul>
                            </li>
                        </ul>
                    </p-accordionTab>
                    <p-accordionTab header="Who should have/create an account in USAS?">
                        <p>Certificate holders/applicants/aircraft owners, and other FAA employees involved in U.S. Agent of Service process can request access to USAS.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Are all the certificate holders/applicants/aircraft owners required to use USAS?">
                        <p>No, all the certificate holders/applicants/aircraft owners are not required to use USAS. Only the individuals who hold or apply for certificates issued under 14 CFR parts 47, 61, 63, 65, 67, or 107 and who do not have a U.S. physical address of record on file with the FAA to designate a U.S. Agent. The U.S. Agent would receive service of FAA documents on behalf of the applicant or certificate holder.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Is there any required information needed before designating a U.S. Agent in USAS?">
                        <p>All certificate holders/applicants/aircraft owners coming to USAS to designate a U.S. Agent will need to have a their FTN/DOB. Aircraft Owners will need to have their N Number and Serial Number.</p>
                    </p-accordionTab>
                    <p-accordionTab header="Can you login to USAS if you don’t have an FTN?">
                        <p>
                            Aircraft registered owners do not need an FTN, but they must have their N Number and Serial Number associated with their account.
                        </p>
                        <p>
                            <span><a href="https://registry.faa.gov/aircraftinquiry" title="Registry" target="_blank">Click here</a></span> to look up your N Number and Serial Number through Aircraft Inquiry.
                        </p>
                        <p>
                            Airmen/Operators must know their FTN and Date of Birth associated with their account.
                        </p>
                        <ul>
                            <li>MedXPress - <span><a href="https://medxpress.faa.gov" title="MedXpress" target="_blank">Click here</a></span> to look up your FTN number in your MedXPress Profile.</li>
                            <li>IACRA - <span><a href="https://iacra.faa.gov" title="IACRA" target="_blank">Click here</a></span> to look up your FTN number in your IACRA Profile.</li>
                        </ul>
                        <p>
                            If you don’t have an FTN, please register with <span><a href="https://iacra.faa.gov" title="IACRA" target="_blank">IACRA</a></span> prior to logging into USAS to create an FTN account.
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="Is there any fee required?">
                        <ul>
                            <li>
                                There are no fees charged by the FAA to provide you U.S. Agent for Service.
                            </li>
                            <li>
                                A U.S. Agent for Service company may charge fees for their services, but these fees are not controlled by the FAA. The FAA does not charge any fees for registering your U.S. Agent for Service.
                            </li>
                        </ul>
                    </p-accordionTab>
                    <p-accordionTab header="Forgot password or trouble logging in">
                        <ul>
                            <li>
                                Please <span><a class="font-medium no-underline link-button cursor-pointer ml-0" 
                                    (click)="forgotPassword()" aria-label="Link to reset password" title="Reset Password">click here</a></span>to reset your password
                            </li>
                        </ul>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</main>

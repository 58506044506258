<main id="main-content" class="grid nested-grid">
    <div class="col-12 mt-4 px-6">
        <div class="grid mb-2">
            <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
            <a class="text-end" style="float:left" href="javascript:history.back()" class="text-end secondary p-button text-white default" title="go back">Go Back</a>
        </div>
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
                <div>
                    <app-landing></app-landing>
                </div>
            </div>
        </div>
    </div>
</main>
import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../../_auth/auth.service';
import { UserDataService } from '../../shared/services/user-data.service';
import { TitleBlinker } from '../../shared/services/tab-blink.service'
import { timer, Subscription, interval, takeUntil, Subject, filter } from 'rxjs';

@Component({
  selector: 'app-user-idle-inactive-timeout',
  templateUrl: './user-idle-inactive-timeout.component.html',
  styleUrls: ['./user-idle-inactive-timeout.component.scss']
})
export class UserIdleInactiveTimeoutComponent implements OnInit {
  private timerSubscription: Subscription;
  public openModal: boolean = false;
  display: boolean = true;
  tokenAvailabilitySubscribe: any;
  endTime: number;
  timeoutWarning: number;
  minutesDisplay = 0;
  secondsDisplay = 0;
  private subscription: Subscription | undefined;
  private destroy$ = new Subject<void>();

  constructor(private userDataService: UserDataService, private titleBlinker: TitleBlinker, private authService: AuthService,) {
  }

  ngOnInit() {
    this.tokenAvailabilitySubscribe = this.userDataService.getTokenAvailability().pipe().subscribe(tokenAvailable => {
      if (tokenAvailable) {
        this.timeoutWarning = this.authService.getUserData().warningTime * 60;
    	this.endTime = this.authService.getUserData().idleTimeout * 60 - this.timeoutWarning;
        this.startSessionTimeout();
      }
    });
  }

  ngOnDestroy() {
    this.stopSessionTimeout();
  }

  private startSessionTimeout() {
    this.openModal = false;
    this.timerSubscription = timer(this.endTime * 1000).subscribe(() => {
      this.openTimeoutModal();
    });
  }

  private stopSessionTimeout() {
    this.timerSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
    this.destroy$.unsubscribe();
  }

  private openTimeoutModal() {
    this.minutesDisplay = Math.floor(this.timeoutWarning / 60);
    this.secondsDisplay = this.timeoutWarning % 60;

    this.subscription = interval(1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => document.visibilityState === 'visible' || document.visibilityState === 'hidden')
      )
      .subscribe(() => {
        console.log(document.visibilityState);
        // Your logic to execute on each interval
        if (this.secondsDisplay > 0) {
          this.secondsDisplay--;
        } else if (this.minutesDisplay > 0) {
          this.minutesDisplay--;
          this.secondsDisplay = 59;
        } else {
          // Timer finished
          this.logoutUser()
          this.subscription?.unsubscribe();
        }
      });
    this.openModal = true;
  }

  public resetSessionTimeout() {
    this.stopSessionTimeout();
    this.startSessionTimeout();
    this.openModal = false;
  }
  logoutUser(event?: any) {
    window.dispatchEvent(new CustomEvent("logoutUser"));
  }

  showTabBlink() {
    this.titleBlinker.blink('Session Timeout Warning');
  }

}


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomLoginService } from '../../shared/services/custom-login.service';
import { UserDetailsService } from '../../shared/services/user-details.service';
import { AuthService } from '../../_auth/auth.service';

interface Questions {
  name: string;
  code: string;
}

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrl: './account-info.component.scss'
})
export class AccountInfoComponent implements OnInit {
  questions: any;

  userForm: FormGroup;
  questionsForm: FormGroup;
  passwordForm: FormGroup;
  passwordError: string;
  newPasswordError: any;
  confirmPasswordError: string;
  answer1Error: string;
  answer2Error: string;
  answer3Error: string;
  uniqueQuestion: boolean = true;
  requestNewAccount:boolean = true;
  dataa: any;

  constructor(private fb: FormBuilder, private customLoginService: CustomLoginService, 
     private messageService: MessageService, public router: Router,
     private userDetailsService: UserDetailsService, private authService: AuthService) {}

  ngOnInit(): void {
    this.questions = [
      { name: 'Question 1', code: 'Q1' },
      { name: 'Question 2', code: 'Q2' },
      { name: 'Question 3', code: 'Q3' },
      { name: 'Question 4', code: 'Q4' },
      { name: 'Question 5', code: 'Q5' }
    ];

    this.passwordForm = this.fb.group({
      newPassword: this.fb.control('', Validators.required),
      confirmPassword: this.fb.control('', Validators.required),
      oldPassword: this.fb.control('', Validators.required)
    });

    this.questionsForm = this.fb.group({
      question1: this.fb.control('', Validators.required),
      question2: this.fb.control('', Validators.required),
      question3: this.fb.control('', Validators.required),
      answer1: this.fb.control('', Validators.required),
      answer2: this.fb.control('', Validators.required),
      answer3: this.fb.control('', Validators.required),
      uniqueQuestion: this.fb.control('')
    });

    this.userForm = this.fb.group({
      firstName: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)]),
      lastName: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)]),
      middleName: this.fb.control('', Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)),
      email: this.fb.control('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9](?!.*[._-]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9])@([a-zA-Z0-9](?!.*[.-]{2})[a-zA-Z0-9.-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,10}$/i), this.forbiddenDomainValidator]),
      confirmEmail: this.fb.control('', [Validators.required,Validators.pattern(/^([a-zA-Z0-9](?!.*[._-]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9])@([a-zA-Z0-9](?!.*[.-]{2})[a-zA-Z0-9.-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,10}$/i), this.forbiddenDomainValidator]),
    });
    this.customLoginService.getAllSecurityQuestions().subscribe(
      (securityQuestions) => {
        this.questions = securityQuestions;
      });
      this.GetExternalUserDetails();
    this.GetUserSecurityQuestions();
  }

  GetExternalUserDetails(){
    const userData = this.authService.getUserData()
    const reqData = {
      externalUserId: userData.userId
    }
    this.userDetailsService.GetExternalUserDetails(reqData).subscribe((data:any) => {
      if (data) {
        this.userForm.patchValue({'firstName':data['firstName']});
        this.userForm.patchValue({'lastName':data['lastName']});
        this.userForm.patchValue({'middleName':data['middleName']});
        this.userForm.patchValue({'email':data['email']});
      }
   });
  }

  GetUserSecurityQuestions(){
    const userData = this.authService.getUserData()
    const reqData = {
      externalUserId: userData.userId
    }
    this.userDetailsService.GetUserSecurityQuestions(reqData).subscribe((data:any) => {
      if (data) {
        this.questions.forEach((element: any) => {
          if(element.securityQuestionDesc == data.securityQuestions[0]['question']){
                this.questionsForm.patchValue({'question1':element});
              }
          if(element.securityQuestionDesc == data.securityQuestions[1]['question']){
            this.questionsForm.patchValue({'question2':element});
          }
          if(element.securityQuestionDesc == data.securityQuestions[2]['question']){
            this.questionsForm.patchValue({'question3':element});
          }
        });
        this.questionsForm.patchValue({'answer1':data.securityQuestions[0]['questionAnswer']});
        this.questionsForm.patchValue({'answer2':data.securityQuestions[1]['questionAnswer']});
        this.questionsForm.patchValue({'answer3':data.securityQuestions[2]['questionAnswer']});
      }
   });
}
UpdateExternalUserDetails(){
  const userData = this.authService.getUserData()
    const reqData = {  
       externalUserId: userData.userId,  
      firstName: this.userForm.get('firstName')?.value,  
      middleName: this.userForm.get('middleName')?.value, 
      lastName: this.userForm.get('lastName')?.value,  
       email: this.userForm.get('email')?.value
       }
    this.userDetailsService.UpdateExternalUserDetails(reqData).subscribe((data:any) => {
      if (data && data.message === 'Success') {
        let userData = this.authService.getUserData();
        userData.firstName  = this.userForm.get('firstName')?.value
        userData.lastName = this.userForm.get('lastName')?.value
        this.authService.updateUser(userData);
        window.dispatchEvent(new CustomEvent("updateHeader"));
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'User data saved/updated successfully',
        });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error updating user data',
          detail: data.message,
        });
      }
   });
}

UpdateUserPassword(){
  const userData = this.authService.getUserData()
    const reqData = { 
        externalUserId: userData.userId,   
        oldPassword: this.passwordForm.controls['oldPassword'].value,   
        newPassword: this.passwordForm.controls['newPassword'].value 
      }
    this.userDetailsService.UpdateUserPassword(reqData).subscribe((data:any) => {
      if (data && data.message === 'Success') {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Password saved/updated successfully',
        });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error Changing Password',
          detail: data.message,
        });
      }
   });
}
UpdateSecurityQuestions(){
  const userData = this.authService.getUserData();
  let securityQuestions:any = [];
  this.questions.forEach((element: any) => {
    for(let i=1; i<=3; i++){
      if(element.securityQuestionDesc == this.questionsForm.controls[`question${i}`].value?.securityQuestionDesc){
        securityQuestions.push ( {
          questionId: element.securityQuestionId,
          question: this.questionsForm.controls[`question${i}`].value?.securityQuestionDesc,
          questionAnswer:this.questionsForm.controls[`answer${i}`].value
        })
      }
    }
  })
    const reqData = { 
        externalUserId: userData.userId,   
        securityQuestions: securityQuestions
      }
    this.userDetailsService.UpdateSecurityQuestions(reqData).subscribe((data:any) => {
      if (data && data.message === 'Success') {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Security questions saved/updated successfully',
        });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error updating security questions',
          detail: data.message,
        });
      }
   });
}

  validateSecurityQuestion(key: any, qstn: any, answr: any) {
    let error = '';
    let question;
    let answer;
    question = this.questionsForm.controls[qstn].value?.securityQuestionDesc;
    answer = this.questionsForm.controls[answr].value;
    

    const isAlpha = (str: string) => /^[A-Za-z\s]+$/.test(str);
    const isAlphaNumeric = (str: string) => /^[A-Za-z0-9\s]+$/.test(str);
    const isFourDigitPIN = (str: string) => /^[0-9]{4}$/.test(str);
    const isNumeric = (str: string) => /^[0-9]+$/.test(str);
    const isDateFormat = (str: string) => /^(0[1-9]|1[0-2])[\/\-]?(0[1-9]|[12][0-9]|3[01])[\/\-]?\d{4}$/.test(str);
    const isPhoneNumber = (str: string) => /^(\d{1,3}-)?\d{3}-\d{3}-\d{4}$/.test(str) && str.replace(/[^0-9]/g, "").length <= 11;

    switch (question) {
      case "Mother's maiden name":
      case "City you were born in":
      case "Street you grew up on":
      case "Pet's name":
      case "Your favorite quote":
      case "Father's first name":
      case "Birth Stone":
      case "Zodiac sign":
      case "Favorite Flower":
      case "Elementary school name":
        if (!isAlpha(answer)) {
          error = "Please check entered value for security question.";
        }
        break;

        case "Four digit PIN":
          if (!isFourDigitPIN(answer)) {
            error = "Please check entered PIN for security question. It must be exactly 4 digits.";
          }
          break;
    
        case "Street number":
          if (!isNumeric(answer)) {
            error = "Please check entered value for security question.";
          }
          break;

      case "Date of birth":
      case "Anniversary date":
        if (!isDateFormat(answer)) {
          error = "Please check entered date for security question. Date must be in the following format (mm/dd/yyyy) or (mmddyyyy)";
        }
        break;

      case "Home Phone":
      case "Cell Phone":
        if (!isPhoneNumber(answer)) {
          error = "Please check entered phone number for security question. Format: 678-900-9088";
          if (answer.replace(/[^0-9]/g, "").length > 11) {
            error = "If CL > 11 CL - Please check entered phone number for security question.";
          }
        }
        break;

      default:
        error ="Select security question.";
    }

    if(key === 'question1') {
      this.answer1Error = error;
    } else if(key === 'question2') {
      this.answer2Error = error;
    } else if(key === 'question3') {
      this.answer3Error = error;
    }

    const question1 = this.questionsForm.controls['question1'].value?.securityQuestionDesc;
    const question2 = this.questionsForm.controls['question2'].value?.securityQuestionDesc;
    const question3 = this.questionsForm.controls['question3'].value?.securityQuestionDesc;

    if (
      (question1 !== question2 ||
        question1 === undefined ||
        question2 === undefined) &&
      (question1 !== question3 ||
        question1 === undefined ||
        question3 === undefined) &&
      (question2 !== question3 ||
        question2 === undefined ||
        question3 === undefined)
    ) {
      this.uniqueQuestion = true;
      this.questionsForm.controls.uniqueQuestion.setErrors(null);
    } else {
      this.uniqueQuestion = false;
      this.questionsForm.controls.uniqueQuestion.setErrors({ incorrect: true });
    }

    if(error.length) {
      this.questionsForm.controls[answr].setErrors({ incorrect: true });
    } else {
      this.questionsForm.controls[answr].setErrors(null);
    }
    this.questionsForm.updateValueAndValidity();
  }


  validatePassword(type: string, control: string) {
    const password = this.passwordForm.controls[control].value;
    console.log('password');
    let errorMessage = '';
 

    // Check if the passwords match
    if (type === 'confirmPassword') {
      if (password === '') {
        errorMessage = 'Confirm Password is required.';
      } else if((this.passwordForm.controls['newPassword'].value !== '') 
        && (password !== this.passwordForm.controls['newPassword'].value)) {
        errorMessage = 'Password and confirm password do not match.';
      } else if ((password.value !== '') && (this.passwordForm.controls['newPassword'].value !== '') 
        && password.value === this.passwordForm.controls['newPassword'].value && password.valid) {
        errorMessage = '';
      }
    } else {
      if (password === '') {
        errorMessage = 'Password is required.';
      } else if (password.length < 12 || password.length > 24) { // Check length requirement
        errorMessage = 'Password must contain between 12 and 24 characters';
      } else if (!/^[a-zA-Z]/.test(password)) { // Check if the password begins with a letter
        errorMessage = 'Password must begin with a letter';
      } else if (/([a-zA-z0-9]|[!#$%])\1/.test(password)) { // Check for no repeated characters in sequence OR (\w|[!#$%])\1
        errorMessage = 'No character may be repeated in sequence';
      } else {
        // Check for required character types
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!#\$%]/.test(password);
        const hasOtherChar = /^[a-zA-Z0-9!#$%]+$/.test(password);
      
        if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar || !hasOtherChar) {
          errorMessage = 'Password must include four types of characters: English upper case letter, English lower case letter, number, and allowable special characters (!, #, $, %)';
        } else if ((this.passwordForm.controls['confirmPassword'].value !== '') 
          && (password !== this.passwordForm.controls['confirmPassword'].value)) {
          errorMessage = 'Password and confirm password do not match.';
        } else if ((this.passwordForm.controls['confirmPassword'].value !== '') && (password.value !== '') 
          && this.passwordForm.controls['confirmPassword'].value === password.value && password.valid) {
          errorMessage = '';
        }
      }
    }

    if(errorMessage.length) {
      if(type === 'newPassword') {
        this.newPasswordError =  errorMessage;
        this.passwordForm.controls.newPassword.setErrors({ incorrect: true });
      } else {
        this.confirmPasswordError =  errorMessage;
        this.passwordForm.controls.confirmPassword.setErrors({ incorrect: true });
      }
    } else {
      if(type === 'newPassword') { 
        this.passwordForm.controls.newPassword.setErrors(null);
      }  if ((this.passwordForm.controls['confirmPassword'].value !== '') && (this.passwordForm.controls['newPassword'].value !== '') 
        && (this.passwordForm.controls['confirmPassword'].value === this.passwordForm.controls['newPassword'].value) && (this.passwordForm.controls['newPassword'].valid || this.newPasswordError ==='Password and confirm password do not match.')) {
          this.passwordForm.controls.confirmPassword.setErrors(null);
          this.passwordForm.controls.newPassword.setErrors(null);
        }
      if(this.passwordForm.controls['confirmPassword'].value ===''){
        this.passwordForm.controls.confirmPassword.setErrors({ incorrect: true });
      }
      if(this.passwordForm.controls['newPassword'].value ===''){
        this.passwordForm.controls.newPassword.setErrors({ incorrect: true });
      }
    }
    this.passwordForm.updateValueAndValidity();
  }

  get email(): AbstractControl { 
    return this.userForm.get('email')!; 
  } 
  
  forbiddenDomainValidator(control: AbstractControl): ValidationErrors | null { 
    const email: string = control.value || ''; 
    return email.endsWith('@faa.gov') ? { forbiddenDomain: true } : null; 
  }
}
